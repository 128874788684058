import { useTranslation } from 'gatsby-plugin-react-i18next';
import gsap from 'gsap';
import { TweenMax } from 'gsap/gsap-core';
import React, { useEffect, useRef } from 'react';
import BannerServicesImg from './banner-services.svg';

const BannerServices = () => {
  const el = useRef()
  const q = gsap.utils.selector(el)
  const {t} = useTranslation()

  useEffect(() => {
    const magnifier = q('#magnifier');
    const chartBar = q('.chart-bar');
    
    TweenMax.from(magnifier, 1, {transformOrigin: "50% 50%", y: '-=10', repeat: -1, yoyo: true});

    TweenMax.staggerTo(chartBar, 2, {scaleY: 0, transformOrigin: "center bottom", repeat: -1, stagger: Math.random() * .5 + .5, yoyo: true});
  }, [])

  return (
    <section ref={el} className="h-full flex flex-col -mt-[109px] max-h-[991px] min-h-[768px] relative">
      <div className="container flex-1 h-full flex flex-col md:flex-row items-center">
        <h1 className="mr-auto relative mt-[8rem] md:mt-0 p-6 md:p-0">
          {t('our')}<br />
          <span className="text-primary font-bold">
            {t('services')}
          </span>
        </h1>
        <div className="banner banner-services">
          <BannerServicesImg />
        </div>
      </div>
    </section>
  )
}

export default BannerServices;
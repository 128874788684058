import React, { useRef } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockRichText from "../components/block-rich-text"
import OurClients from "../components/home/our-clients"
import FreeConsultationForm from "../components/free-consultation-form"
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import BannerServices from "../components/banner/services"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import { IconButton } from "../components/icon-link-button"
import scrollTo from "gatsby-plugin-smoothscroll"

function DesktopNav({
  serviceNavRef,
  nodes,
}) {
  return (
    <div ref={serviceNavRef} className="bg-secondary lg:bg-primary text-white">
      <div className="lg:container lg:py-4 flex flex-col lg:flex-row text-center justify-around">
        {nodes.map(({
          id,
          slug,
          title,
        }) => (
          <Link
            key={`service-link-${id}`}
            className="py-2 group block lg:inline-flex items-center px-2 lg:font-bold text-2xl border-b-2 border-white lg:border-b-0 text-white"
            activeClassName="font-bold lg:text-secondary"
            to={`/service/${slug}`}
          >
            {title}
            <span className="hidden lg:inline-block group-last:hidden text-secondary font-normal ml-4">|</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

function FeatureItem({
  childStrapiComponentSharedFeatureItemBodyTextnode: bodyParent,
  image,
  title,
}) {
  return (
    <div className="text-center space-y-4">
      { image && <GatsbyImage image={getImage(image.localFile)} alt={image.alternativeText} /> }
      <h4 className="text-2xl font-bold">{title}</h4>
      { bodyParent && <BlockRichText data={bodyParent.body} /> }
    </div>
  )
}

const ServicePage = ({ data, pageContext }) => {
  const serviceNavRef = useRef(null)
  const { t } = useTranslation()
  const { ourClients } = data.strapiHome
  const service = data.strapiService
  const { contactDetails } = data.strapiGlobal
  const { servicesOrder } = data.services
  const nodes = servicesOrder.map(({ service }) => service);

  const seo = {
    metaTitle: service.title,
    lang: pageContext.strapiContext,
    ...(service.seo || {}),
  }

  const {
    body,
    featureImage,
    featureItem,
    featureVideoUrl,
    slug,
    title,
  } = service;

  function scrollToNav() {
    if (!serviceNavRef) {
      return;
    }

    serviceNavRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const isMainPage = slug === "systems";

  return (
    <Layout as="service">
      <Seo seo={seo} />
      { isMainPage && <BannerServices /> }
      <DesktopNav {...{ nodes, serviceNavRef }} />
      <main>
        <section className="bg-concrete">
          <div className="service-section">
            <h1 className="text-[50px] md:text-[70px] text-primary font-bold text-neutral-700 text-center mb-[32px]">
              {title}
            </h1>
            <div className="grid grid-cols-1 gap-y-10 lg:grid-cols-5 lg:gap-12">
              { 
                featureImage && 
                <>
                  <GatsbyImage
                    className="col-span-2 justify-self-center"
                    // @ts-ignore-next-line
                    image={getImage(featureImage!.localFile)}
                    alt={featureImage.alternativeText}
                  />
                  <div className="col-span-3 mt-10 text-center">
                    <BlockRichText className=" md:text-xl" data={body.data.body}  />

                    <IconButton
                      className="mt-10"
                      onClick={() => scrollTo('#free-consultation-form')}
                    >
                      Talk to us
                    </IconButton>
                  </div>
                </>
              }
              {
                featureVideoUrl &&
                <>
                  <video 
                    className="col-span-3 mt-10 text-center"
                    autoPlay 
                    muted 
                    controls
                  >
                    <source src={ featureVideoUrl } type="video/mp4"/>
                  </video>
                  <div className="col-span-2 mt-10 text-center self-center">
                    <BlockRichText className=" md:text-xl" data={body.data.body}  />

                    <IconButton
                      className="mt-10"
                      onClick={() => scrollTo('#free-consultation-form')}
                    >
                      Talk to us
                    </IconButton>
                  </div>
                </>
              }

            </div>
          </div>
        </section>
        <section className="service-section grid sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-16">
          { featureItem.map((itemData) => (
            <FeatureItem
              key={itemData.id}
              {...itemData}
            />
          ))}
        </section>
        <section className="container mb-20 space-y-16 text-center">
          <p>
            {t('If you are interested in any of our services, please contact us using our details below or email us at')}
            {/* <br className="hidden xl:block" /> */}
            &nbsp;
            <a className="text-primary" href="mailto:info@advisoryapps.com">info@advisoryapps.com</a>
          </p>
          <IconButton
            onClick={scrollToNav}
            icon={faChevronUp}
          >
            {t('view other services')}
          </IconButton>
        </section>
        <OurClients data={ourClients} />
        <FreeConsultationForm {...{ contactDetails }} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query (
    $language: String!
    $slug: String
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    strapiHome(locale: {eq: $strapiLangKey}) {
      ourClients {
        id
        file {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "white"
                transformOptions: {fit: CONTAIN}
                width: 260
                height: 170
                placeholder: TRACED_SVG
              )
             }
            }
           }
          }
         }  
    strapiService(
      slug: { eq: $slug }
      locale: { eq: $strapiLangKey }
    ) {
      seo {
        ...Seo
      }
      slug
      title
      body {
        data {
          body
        }
      }
      featureVideoUrl
      featureItem {
        id
        title
        childStrapiComponentSharedFeatureItemBodyTextnode {
          body
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
      featureImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    }
    services: strapiGlobal(
      locale: { eq: $strapiLangKey }
    ) {
      ...ServicesOrder
    }
  }
`

export default ServicePage
